import { createWebHistory, createRouter } from "vue-router";
import LandingPage from "@/views/LandingPage.vue";
import ForStartUps from "@/views/ForStartUps.vue";
import ForEnterprises from "@/views/ForEnterprises.vue";
import WebMobileAppDevelopment from "@/views/WebMobileAppDevelopment.vue";
import TechnologyConsulting from "@/views/TechnologyConsulting.vue";
import DataAnalytics from "@/views/DataAnalytics.vue";
import ContactForm from "@/views/ContactForm.vue";
import AboutCompany from "@/views/AboutCompany.vue";
import AvlosCareers from "@/views/AvlosCareers.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import ThankYou from "@/views/ThankYou.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: LandingPage,
  },
  {
    path: "/startups",
    name: "ForStartUps",
    component: ForStartUps,
  },
  {
    path: "/enterprises",
    name: "ForEnterprises",
    component: ForEnterprises,
  },
  {
    path: "/development",
    name: "WebMobileAppDevelopment",
    component: WebMobileAppDevelopment,
  },
  {
    path: "/consulting",
    name: "TechnologyConsulting",
    component: TechnologyConsulting,
  },
  {
    path: "/analytics",
    name: "DataAnalytics",
    component: DataAnalytics,
  },
  {
    path: "/company",
    name: "AboutCompany",
    component: AboutCompany,
  },
  {
    path: "/contact",
    name: "ContactForm",
    component: ContactForm,
  },
  {
    path: "/careers",
    name: "AvlosCareers",
    component: AvlosCareers,
  },
  {
    path: "/tos",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/pp",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/404",
    component: PageNotFound,
  },
  {
    path: "/thanks",
    component: ThankYou,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
