<template>
  <div id="d3-map"></div>
</template>
<script>
import * as d3 from 'd3';

export default {
  props: {

  },
  mounted() {
    this.renderMap();
  },
  methods: {
    async renderMap() {
      var map = d3.select('#d3-map')
      var margin = { top: 10, left: 10, bottom: 10, right: 10 },
        width = 1000 - margin.left - margin.right,
        mapRatio = .4,
        height = width * mapRatio,
        scale = 70;

      var svg = map
        .append("svg")
        .attr("viewBox", "280 50 400 200")
        .attr("preserveAspectRatio", "xMinYMin");


      let reposition = 0
      let scaleBoundingBox = 6
      let angle = 1.75 * Math.PI
      let distance = 0
      let blur = 2
      let shadowColor = '#E85700'
      let shadowOpacity = 1

      var dropShadow = svg
        .append("filter")
        .attr("id", "dropshadow")
        .attr("x", (1 - scaleBoundingBox) / 2 + reposition * Math.cos(angle))
        .attr("y", (1 - scaleBoundingBox) / 2 - reposition * Math.sin(angle))
        .attr("width", scaleBoundingBox)
        .attr("height", scaleBoundingBox)
        .attr("filterUnits", "objectBoundingBox"); // userSpaceOnUse or objectBoundingBox
      dropShadow.append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", blur)
        .attr("result", "blur");
      dropShadow.append("feOffset")
        .attr("in", "blur")
        .attr("dx", distance * Math.cos(angle))
        .attr("dy", distance * -Math.sin(angle))
        .attr("result", "offsetBlur")
      dropShadow.append("feFlood")
        .attr("in", "offsetBlur")
        .attr("flood-color", shadowColor)
        .attr("flood-opacity", shadowOpacity)
        .attr("result", "offsetColor");
      dropShadow.append("feComposite")
        .attr("in", "offsetColor")
        .attr("in2", "offsetBlur")
        .attr("operator", "in")
        .attr("result", "offsetBlur");

      var feMerge = dropShadow.append("feMerge");
      feMerge.append("feMergeNode")
        .attr("in", "offsetBlur")
      feMerge.append("feMergeNode")
        .attr("in", "SourceGraphic");


      var projection = d3.geoMercator()
        .center([0, 0])
        .translate([width / 2, height / 2])
        .scale(scale);

      var path = d3.geoPath()
        .projection(projection);

      var g = svg.append("g");
      var worldmap = d3.json("https://cdn.avlos.io/countries.json")
      var cities = d3.csv("https://cdn.avlos.io/cities.csv")

      Promise.all([worldmap, cities]).then(function(values) {
        g.selectAll("path")
          .data(values[0].features)
          .enter()
          .append("path")
          .attr("d", path);

        g.selectAll("circle")
          .data(values[1])
          .enter()
          .append("circle")
          .attr("class", "circles glowed")
          .attr("cx", function(d) {
            return projection([d.lon, d.lat])[0];
          })
          .attr("cy", function(d) {
            return projection([d.lon, d.lat])[1];
          })
          .attr("r", 1.5)
          .style("stroke-width", "1.5px")
          .attr("filter", "url(#dropshadow)")
          .style("fill", "#E85700")
          .style("opacity", "0.9");


        g.selectAll("text")
          .data(values[1])
          .enter().append("text")
          .attr("text-anchor", "middle")
          .style("font-size", "0.2em")
          .attr("x", (d) => projection([d.lon, d.lat])[0])
          .attr("y", (d) => projection([d.lon, d.lat])[1] - 5)
          .text(function(d) { return d.city })
          .attr("class", "test")
          .style("fill", "white");
      });
    }
  }
};
</script>
<style lang="scss">
#d3-map {
  path {
    stroke: #242424;
    stroke-width: 0.25px;
    fill: #141414;
  }
}


#d3-map {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #070707;
}
</style>
