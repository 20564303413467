<template>
  <a class="cursor-pointer text-white rounded-md group" :href="link">
    <div class="gs_reveal transform transition duration-500 hover:scale-105 card" :class="['card-link relative', heightStyle]">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover rounded-2xl" :src="img" alt="" />
      </div>
      <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-1">
        <p class="mt-6 max-w-3xl text-xl">{{name}}</p>
      </div>
      <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-1 ">
        <p class="mt-6 max-w-3xl text-base sm:hidden sm:group-hover:block">{{descr}}</p>
      </div>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-4 absolute bottom-2">
        <p class="max-w-3xl text-base text-avl-copper sm:text-white group-hover:text-avl-copper">See Details</p>
      </div>
    </div>
  </a>
</template>
<script>
import MixinContent from '@/mixins/Content.js';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  mixins: [MixinContent],
  props: {
    name: String,
    link: String,
    img: String,
    descr: String,
    heightStyle: String,
  },
  mounted() {
    let self = this;
    self.batch(".card", {
      interval: 0.1,
      batchMax: 3,
      onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.15, overwrite: true }),
      once: true
    });
  },
};
</script>
<style lang="scss">
@import "@/styles/global.scss";
</style>