<template>
  <div class="bg-grayscale-off-white font-geo text-sm">
    <div class="grid grid-cols-9 gap-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 sm:pb-[90px] pt-[90px]">
      <div class="col-span-9 border-b-2 border-grayscale-line"></div>
      <div class="col-span-9">
        <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-2">
          Privacy Policy
        </p>
        <p class="mb-10 text-avl-bg-grey">Effective Date: 10/30/2022</p>
        <p>
          Your privacy is critically important to us. At Avlos, we have a few
          fundamental principles:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            We are thoughtful about the personal information we ask you to provide
            and the personal information that we collect about you through the
            operation of our services.
          </li>
          <li>
            We store personal information for only as long as we have a reason to
            keep it.
          </li>
          <li>
            We aim for full transparency on how we gather, use, and share your
            personal information.
          </li>
        </ul>
        <p>
          Below is our Privacy Policy, which incorporates and clarifies these
          principles.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          What This Policy Covers
        </h2>
        <p>
          This Privacy Policy applies to information that we collect about you when
          you use:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            Our website (<a href="https://avlos.ai">avlos.ai</a>,
            <a href="https://avlos.llc">avlos.llc</a>,
            <a href="https://avlos.tech">avlos.tech</a>,
            <a href="https://avlos.ai">avlos.io</a> );
            and
          </li>
          <li>
            Our other Avlos services offered through our website.
          </li>
        </ul>
        <p>
          Throughout this Privacy Policy we’ll refer to our website and services
          collectively as “Services.”
        </p>
        <p>
          By visiting our website directly or through another site, you accept the
          terms and conditions of this Policy. Please note that we are not
          responsible for the content or privacy practices on any website not
          operated by Avlos to which our Services links or that links to our
          Services.
        </p>
        <p>
          Below we explain how we collect, use, and share information about you,
          along with the choices that you have with respect to that information. For
          instance, visitors to our website have the option to browse our website
          without providing personal data.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Creative Commons Sharealike License
        </h2>
        <p>
          This Privacy Policy is available under a
          <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">
            Creative Commons Sharealike
          </a>
          license. This Privacy Policy is a modified version of the Privacy Policy
          made available by <a href="https://automattic.com/privacy/" target="_blank">Automattic</a>
          on February 1, 2019 under the same
          <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank">
            Creative Commons Sharealike
          </a>
          license. Revisions have been made to exclude sections that are not
          pertinent to Avlos’s services as well as to include sections that reflect
          additional Avlos practices and policies.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Information We Collect
        </h2>
        <p>
          We only collect information about you if we have a reason to do so–for
          example, to provide our Services, to communicate with you, or to make our
          Services better.
        </p>
        <p>
          We collect information in three ways: if and when you provide information
          to us, automatically through using our Services, and from outside sources.
          Let’s go over the information that we collect.
        </p>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Information You Provide to Us
        </h3>
        <p>
          It’s probably no surprise that we collect information that you provide to
          us. The amount and type of information depends on the context and how we
          use the information. Here are some examples:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            <span class="font-geo-md">Transaction and Billing Information: </span>
            If you use our services – you may provide us with personal and payment
            information that is required to process the transaction and your
            payment, such as your name, email address, company name and address or
            your bank account information.
          </li>
          <li>
            <span class="font-geo-md">Credentials:</span>
            Depending on the Services you use, you may provide us with credentials
            for your website (like SSH, FTP, and SFTP username and password). For
            example, clients may provide us with these credentials in order to
            allow us to troubleshoot problems on their site or applications more
            quickly.
          </li>
          <li>
            <span class="font-geo-md">Communications with Us (Hi There!):</span>
            You may also provide us information when you respond to surveys,
            communicate with our us about a support question, post a question about
            your site in our public forums.
          </li>
          <li>
            <span class="font-geo-md">Recruitment Process: </span>
            If you have applied to take part of our recruitment process, Avlos will
            maintain any personal data provided by you until the outcome of the
            recruitment process has been settled or until otherwise specified by
            you.
          </li>
        </ul>
        <p>
          You are required to provide us with real and up-to-date data and should not
          provide personal data of third parties unless you have obtained consent
          from the third parties to transfer this data to Avlos.
        </p>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Information We Collect Automatically
        </h3>
        <p>
          We also collect some information automatically:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            <span class="font-geo-md">Log Information:</span>
            Like most online service providers, we collect information that web
            browsers, mobile devices, and servers typically make available, such as
            the browser type, IP address, unique device identifiers, language
            preference, referring site, the date and time of access, operating
            system, and mobile network information.
          </li>
          <li>
            <span class="font-geo-md">Usage Information:</span>
            We may collect information about what happens when you use our Services
            (e.g. page views) along with information about your device (e.g. screen
            size, name of cellular network, and mobile device manufacturer). We use
            this information to provide our Services to you, as well as get
            insights on how people use our Services, so we can make our Services
            better.
          </li>
          <li>
            <span class="font-geo-md">Location Information:</span>
            We may determine the approximate location of your device from your IP
            address. We collect and use this information to, for example, calculate
            how many people visit our Services from certain geographic regions.
          </li>
          <li>
            <span class="font-geo-md">Information from Cookies &amp; Other Technologies:</span>
            A cookie is a string of information that a website stores on a
            visitor’s computer, and that the visitor’s browser provides to the
            website each time the visitor returns. Pixel tags (also called web
            beacons) are small blocks of code placed on websites and emails. Avlos
            uses cookies and other technologies like pixel tags to help us identify
            and track visitors, usage, and access preferences for our Services, as
            well as track and understand email campaign effectiveness and to
            deliver targeted ads.
          </li>
        </ul>
        <p>
          The cookies our Services use are the following:
        </p>
        <div class="row cookies py-3">
          <div class="col-md-2">
            <em>utma / utmb / utmc / utmt / utmz / ga / gat / gid</em>
          </div>
          <div class="col-md-10">
            Google Analytics. These cookies help us gather information to understand how visitors interact with our website to create a better experience for our visitors. You find more information about Google Analytics cookies by reading “
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank">
              Google Analytics Cookie Usage on Websites
            </a>
            ” or “
            <a href="https://policies.google.com/technologies/partner-sites" target="_blank">
              How Google uses information from sites or apps that use our services
            </a>
            ”.
          </div>
        </div>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Information We Collect from Other Sources
        </h3>
        <p>
          We may also get information about you from other sources. For example, if
          you interact with our accounts on social media services (like Twitter or
          Facebook), we may receive information from those services (such as your
          username, basic profile information, and friends list) via the
          authorization procedures used by those services. The information we receive
          depends on which services you authorize and any options that are made
          available by them.
        </p>
        <p>
          We may also get information, such as a mailing address, from third party
          services about individuals who are not yet our users (…but we hope will
          be!), which we may use, for example, for marketing and advertising purposes
          like postcards and other mailers advertising our services.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          How and Why We Use Information
        </h2>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Purposes for Using Information
        </h3>
        <p>
          We use information about you as mentioned above and for the purposes listed
          below:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            To provide our Services – for example, to set up, maintain and host your
            website, backup and restore your applications, charge you for any of our
            paid Services;
          </li>
          <li>
            To further develop and improve our Services–for example, by adding new
            features that we think our users will enjoy or will help them to create and
            manage their websites and applications more efficiently;
          </li>
          <li>
            To monitor and analyze trends and better understand how users interact
            with our Services, which helps us improve our Services and make them easier
            to use;
          </li>
          <li>
            To measure, gauge, and improve the effectiveness of our advertising, and
            better understand user retention and attrition — for example, we may
            analyze how many individuals used our Services after receiving a marketing
            message or the features used by those who continue to use our Services
            after a certain length of time;
          </li>
          <li>
            To monitor and prevent any problems with our Services, protect the
            security of our Services, detect and prevent fraudulent transactions and
            other illegal activities, fight spam, and protect the rights and property
            of Avlos and others, which may result in us declining a transaction or the
            use of our Services;
          </li>
          <li>
            To communicate with you, for example through an email, about offers and
            promotions offered by Avlos and others we think will be of interest to you,
            solicit your feedback, or keep you up to date on Avlos and our Services;
            and
          </li>
          <li>
            To personalize your experience using our Services, provide
            content recommendations (for example, through our posts on our social
            media profiles), target our marketing messages to groups of our users
            (for example, those who have been our client for a certain length of
            time), and serve relevant advertisements.
          </li>
        </ul>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Legal Bases for Collecting and Using Information
        </h3>
        <p>
          A note here for those in the European Union about our legal grounds for
          processing information about you under EU data protection laws, which is
          that our use of your information is based on the grounds that:
        </p>
        <ol>
          <li>
            The use is necessary in order to fulfill our commitments to you under
            our <a href="/tos">Terms of Service</a> or other agreements
            with you — for example, in order to charge you for a paid service; or
          </li>
          <li>
            The use is necessary for compliance with a legal obligation; or
          </li>
          <li>
            The use is necessary in order to protect your vital interests or those
            of another person; or
          </li>
          <li>
            We have a legitimate interest in using your information — for example,
            to provide and update our Services, to improve our Services so that we can
            offer you an even better user experience, to safeguard our Services, to
            communicate with you, to measure, gauge, and improve the effectiveness of
            our advertising, and better understand user retention and attrition, to
            monitor and prevent any problems with our Services, and to personalize your
            experience; or
          </li>
          <li>
            You have given us your consent — for example before we place certain
            cookies on your device and access and analyze them later on.
          </li>
        </ol>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Sharing Information
        </h2>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          How We Share Information
        </h3>
        <p>
          We do not sell our users’ private personal information.
        </p>
        <p>
          We share information about you in the limited circumstances spelled out
          below and with appropriate safeguards on your privacy:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            <span class="font-geo-md">Subsidiaries, Employees, and Independent Contractors:</span>
            We may disclose information about you to our subsidiaries, our
            employees, and individuals who are our independent contractors that
            need to know the information in order to help us provide our Services
            or to process the information on our behalf. We require our
            subsidiaries, employees, and independent contractors to follow this
            Privacy Policy for personal information that we share with them.
          </li>
          <li>
            <span class="font-geo-md">Third Party Vendors:</span>
            We may share information about you with third party vendors who need to
            know information about you in order to provide their services to us, or
            to provide their services to you or your site or application. This
            group includes vendors that help us provide our Services to you (like
            payment providers that process your billing information, postal and
            email delivery services that help us stay in touch with you, customer
            chat and email support services that help us communicate with you), those
            that assist us with our marketing efforts (e.g. by providing tools for
            identifying a specific marketing target group or improving our
            marketing campaigns), and those that help us understand and enhance our
            Services (like analytics providers). We require vendors to agree to
            privacy commitments in order to share information with them.
          </li>
          <li>
            <span class="font-geo-md">Legal Requests:</span>
            We may disclose information about you in response to a subpoena, court
            order, or other governmental request.
          </li>
          <li>
            <span class="font-geo-md">To Protect Rights, Property, and Others:</span>
            We may disclose information about you when we believe in good faith
            that disclosure is reasonably necessary to protect the property or
            rights of Avlos, third parties, or the public at large.
          </li>
          <li>
            <span class="font-geo-md">Business Transfers:</span>
            In connection with any merger, sale of company assets, or acquisition
            of all or a portion of our business by another company, or in the
            unlikely event that Avlos goes out of business or enters bankruptcy,
            user information would likely be one of the assets that is transferred
            or acquired by a third party. If any of these events were to happen,
            this Privacy Policy would continue to apply to your information and the
            party receiving your information may continue to use your information,
            but only consistent with this Privacy Policy.
          </li>
          <li>
            <span class="font-geo-md">With Your Consent:</span>
            We may share and disclose information with your consent or at your
            direction. For example, we may share your information with third
            parties with which you authorize us to do so.
          </li>
          <li>
            <span class="font-geo-md">Aggregated or De-Identified Information:</span>
            We may share information that has been aggregated or reasonably
            de-identified, so that the information could not reasonably be used to
            identify you. For instance, we may publish aggregate statistics about
            the use of our Services.
          </li>
          <li>
            <span class="font-geo-md">Published Support Requests:</span>
            And if you send us a request (for example, via a support email or one
            of our feedback mechanisms), we reserve the right to publish that
            request in order to help us clarify or respond to your request or to
            help us support other users.
          </li>
        </ul>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          How Long We Keep Information
        </h3>
        <p>
          We generally discard information about you when we no longer need the
          information for the purposes for which we collect and use it — which are
          described in the section above on How and Why We Use Information — and we
          are not legally required to continue to keep it.
        </p>
        <p>
          For example, we keep the web server logs that record information about a
          visitor to Avlos’s website, such as the visitor’s IP address, browser type,
          and operating system, for approximately 30 days. We retain the logs for
          this period of time in order to, among other things, analyze traffic to
          Avlos’s website and investigate issues if something goes wrong on our
          website.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
           Security
        </h2>
        <p>
          While no online service is 100% secure, we work very hard to protect
          information about you against unauthorized access, use, alteration, or
          destruction, and take reasonable measures to do so, such as monitoring our
          Services for potential vulnerabilities and attacks.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Choices
        </h2>
        <p>
          You have several choices available when it comes to information about you:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            <span class="font-geo-md">Opt-Out of Electronic Communications:</span>
            You may opt out of receiving promotional messages from us. Just follow
            the instructions in those messages. If you opt out of promotional
            messages, we may still send you other messages, like those about your
            account and legal notices.
          </li>
          <li>
            <span class="font-geo-md">Set Your Browser to Reject Cookies:</span>
            At this time, Avlos does not respond to “do not track” signals across
            all of our Services. However, you can usually choose to set your
            browser to remove or reject browser cookies before using Avlos’s
            website.
          </li>
        </ul>
        <p>
          You also have the option to opt out of Google Analytics by installing
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
            Google’s opt-out browser add-on
          </a>.
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            <span class="font-geo-md">Discontinue our Services:</span>
            While we’d be very sad to see you go, if you no longer want to use our
            Services, please keep in mind that we may continue to retain your
            information, as described in How Long We Keep Information above — for
            example, when that information is reasonably needed to comply with (or
            demonstrate our compliance with) legal obligations such as law
            enforcement requests, or reasonably needed for our legitimate business
            interests.
          </li>
        </ul>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Your Rights
        </h2>
        <p>
          If you are located in certain countries, including those that fall under
          the scope of the European General Data Protection Regulation (AKA the
          “GDPR”), data protection laws give you rights with respect to your personal
          data, subject to any exemptions provided by the law, including the rights
          to:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            Request access to your personal data;
          </li>
          <li>
            Request correction or deletion of your personal data;
          </li>
          <li>
            Object to our use and processing of your personal data;
          </li>
          <li>
            Request that we limit our use and processing of your personal data; and
          </li>
          <li>
            Request portability of your personal data.
          </li>
        </ul>
        <p>
          You can usually access, correct, or delete your personal data by contacting
          us at <a href="mailto:privacy@avlos.ai">privacy@avlos.ai</a>.
        </p>
        <p>
          EU individuals also have the right to make a complaint to a government
          supervisory authority.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Controllers and Responsible Companies
        </h2>
        <p>
          Avlos LLC, a US-based company, is the controller for the processing
          activities across all of the Services we offer worldwide.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          How to Reach Us
        </h2>
        <p>
          If you have a question about this Privacy Policy, or you would like to
          contact us about any of the rights mentioned in the Your Rights section
          above, please contact us at <a href="mailto:privacy@avlos.ai">privacy@avlos.ai</a>
          or through our <a href="/contact">website</a>.
        </p>
        <h2 class="text-2xl sm:text-3xl lg:text-4xl text-black my-4">
          Other Things You Should Know (Keep Reading!)
        </h2>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Transferring Information
        </h3>
        <p>
          Because Avlos’s Services are offered worldwide, the information about you
          that we process when you use the Services in the EU may be used, stored,
          and/or accessed by individuals operating outside the European Economic Area
          (EEA) who work for us, or third party data processors. This is required for
          the purposes listed in the How and Why We Use Information section above.
          When providing information about you to entities outside the EEA, we will
          take appropriate measures to ensure that the recipient protects your
          personal information adequately in accordance with this Privacy Policy as
          required by applicable law. These measures include:
        </p>
        <ul class="list-disc list-outside pb-4">
          <li>
            In the case of US based entities, entering into European Commission
            approved standard contractual arrangements with them, or ensuring they have
            signed up to the <a href="https://www.privacyshield.gov/welcome" target="_blank">EU-US Privacy Shield</a>;
            or
          </li>
          <li>
            In the case of entities based in other countries outside the EEA,
            entering into European Commission approved standard contractual
            arrangements with them.
          </li>
          <li>
            You can ask us for more information about the steps we take to protect your
            personal information when transferring it from the EU.
          </li>
        </ul>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Ads and Analytics Services Provided by Others
        </h3>
        <p>
          Ads appearing on any of our Services may be delivered by advertising
          networks. Other parties may also provide analytics services via our
          Services. These ad networks and analytics providers may set tracking
          technologies (like cookies) to collect information about your use of our
          Services and across other websites and online services. These technologies
          allow these third parties to recognize your device to compile information
          about you or others who use your device. This information allows us and
          other companies to, among other things, analyze and track usage, determine
          the popularity of certain content, and deliver advertisements that may be
          more targeted to your interests. Please note this Privacy Policy only
          covers the collection of information by Avlos and does not cover the
          collection of information by any third party advertisers or analytics
          providers.
        </p>
        <h3 class="text-xl sm:text-2xl lg:text-3xl my-1 text-avl-bg-grey">
          Privacy Policy Changes
        </h3>
        <p>
          Avlos may change its Privacy Policy from time to time. Avlos encourages
          visitors to frequently check this page for any changes to its Privacy
          Policy. If we make changes, we may provide additional notice (such as
          adding a statement to our homepage). Your further use of the Services after
          a change to our Privacy Policy will be subject to the updated policy.
        </p>
        <p>
          That’s it! Thanks for reading
        </p>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
p {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

li {
  margin-left: 1em;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
</style>