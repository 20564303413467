<template>
  <div id="root">
    <div class="bg-grayscale-off-white">
      <nav-bar />
      <router-view />
      <site-footer />
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/layout/NavBar.vue";
import SiteFooter from "@/components/layout/SiteFooter.vue";
export default {
  components: {
    NavBar,
    SiteFooter,
  },
};
</script>
<style>
@font-face {
  font-family: "Geograph";
  src: local("Geograph"), url(./fonts/Geograph-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Geograph-Medium";
  src: local("Geograph-Medium"),
    url(./fonts/Geograph-Medium.otf) format("opentype");
}
</style>
