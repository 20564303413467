<template>
  <div id="DataAnalytics" class="bg-black font-geo">
    <PageHeader heading="Avlos data and analytics" subheading="Turn your data into value." img="/img/avlos-header-analytics.png" />
    <div class="bg-white py-12 sm:py-[90px]">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-2 gap-2 pb-10" v-for="item in items" :key="item.name">
        <div class="col-span-2 sm:col-span-1 text-2xl sm:text-3xl lg:text-4xl max-w-sm">
          <div class="">{{item.name}}</div>
        </div>
        <div class="col-span-2 md:col-span-1 border-b-2 border-grayscale-line pb-5">
          <p class="text-md mb-5" v-for="(pt, i) in item.pts" :key="item.name + i">
            {{ pt }}
          </p>
          <check-list v-if="item.checklist" class="text-md text-black mb-5" :list="item.checklist" />
        </div>
      </div>
    </div>
    <div class="bg-grayscale-off-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 py-12 sm:py-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-2xl sm:text-3xl lg:text-4xl pb-10">
            Industries
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-0" />
        </div>
      </div>
    </div>
    <next-step />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import NextStep from "@/components/NextStep.vue";
import FeatCard from "@/components/FeatCard.vue";
import MixinContent from '@/mixins/Content.js';
import CheckList from "@/components/CheckList.vue";

export default {
  name: "DataAnalytics",
  mixins: [MixinContent],
  components: {
    PageHeader,
    NextStep,
    FeatCard,
    CheckList,
  },
  data() {
    return {
      items: [{
          name: "Connect insights to results",
          pts: [
            "We design, develop and implement resilient custom products, platforms and solutions.",
            "We ensure that your data and analytics solutions will have a robust technology foundation that connects to the rest of the architecture.",
            "Our services offer solutions to a broad range of industries, from health care to industrial goods and more."
          ]
        },
        {
          name: "Make your data work for you",
          pts: [
            "Our data scientists identify how clients can use machine learning and AI, and then leverage our software solutions expertise to develop processes that can integrate with existing systems, and scale throughout your organization. ",
            "The approach we follow covers the following steps:"
          ],
          checklist: [
            "Define goals",
            "Develop proof-of-concept models",
            "Develop minimally viable products",
            "Scale and industrialize",
            "Operate and improve"
          ]
        },
      ],
      feats: ["healthcare & life sciences", "sustainability", "telecommunications", "financial services", "government", "education"],
    }
  }
};
</script>
<style></style>