<template>
  <div class="bg-black font-geo">
    <div class="mx-auto max-w-full h-[95vh] xl:h-auto lg:pt-4 2xl:pt-16">
      <div class="relative text-center py-24 2xl:py-10 sm:pt-0">
        <div class="absolute inset-0 flex justify-center">
          <img id="headerImg" class="w-full object-cover" src="/img/avlos-header-home.png" alt="" />
        </div>
        <div class="relative mx-auto max-w-7xl py-24 sm:py-52 2xl:py-96 justify-center">
          <h1 class="tracking-tight text-white text-3xl lg:text-5xl">
            Thank you!
          </h1>
          <p class="mt-1 max-w-xl mx-auto text-lg sm:text-lg text-avl-bg-grey">
            Someone from the Avlos team will be reaching out soon!
          </p>
          <div class="mt-6">
            <a href="/" class="inline-flex items-center border border-transparent bg-avl-copper px-4 py-2 text-sm font-medium text-black text-opacity-75">Go back home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>