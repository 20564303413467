<template>
  <div :class="['card-link relative w-full h-full shadow-lg px-8 py-5 bg-zinc-100', minHeight]">
    <p class="capitalize text-sm pb-3">{{name}}</p>
    <p class="text-sm">{{descr}}</p>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    descr: String,
    minHeight: String,
  }
};
</script>
<style lang="scss">
@import "@/styles/global.scss";
</style>