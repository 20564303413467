import { createApp } from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import "@/index.css";
import { defineCustomElements } from "@avlos/avl-components/dist/loader";

const app = createApp(App);
defineCustomElements(window);

app.mixin({
  methods: {
    handleScroll(evt, el) {
      if (
        window.scrollY >
        el.offsetTop -
          ((evt.composedPath && evt.composedPath()[1].innerHeight - 50) ||
            evt.path[1].innerHeight - 50)
      ) {
        el.setAttribute("style", "opacity: 1; transform: translateY(0px);");
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    goToWindowLocation(path) {
      window.open(path);
    },
  },
});
app.directive("scroll", {
  mounted: function (el, binding) {
    const smoothScrolling = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", smoothScrolling);
      }
    };
    window.addEventListener("scroll", smoothScrolling);
  },
});

app.use(router).use(store).mount("#app");
