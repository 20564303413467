<template>
  <div class="bg-grayscale-off-white font-geo">
    <div class="grid grid-cols-9 gap-10  mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 sm:pb-[90px] pt-[90px]">
      <div class="col-span-9 border-b-2 border-grayscale-line"></div>
      <div class="col-span-9">
        <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-2">
          Terms of Service
        </p>
        <p class="text-sm mb-10 text-avl-bg-grey">Effective Date: 10/30/2022</p>
        <p class="text-sm">
          We at Avlos provide software services for businesses in the media &amp; entertainment and learning industries. Our services range from providing digital asset management solutions to developing, maintaining, scaling websites and applications for our clients.
        </p>
        <p class="text-sm pt-4">
          (The Terms of Services below has been adapted for our purposes from <a href="https://en.wordpress.com/tos/" target="_blank" class="text-avl-copper"> Automattic's Terms of Service</a> made available under a <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" class="text-avl-copper">Creative Commons Sharealike</a> license. The <a href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" class="text-avl-copper"> Creative Commons Sharealike</a> license is also applied to the below Terms of Service.)
        </p>
        <p class="text-sm pt-4">
          The following terms and conditions ("Terms") govern all use of the <a href="/">avlos.ai</a> website and all content, services, and products available at or through the website (taken together, our "Services"). If you are a Customer and you or your organization is bound by a Master Services Agreement with Avlos ("MSA"), then these Terms will apply, if at all, only to the use of the Services to the extent such use is not already governed by such an MSA.
        </p>
        <p class="text-sm pt-4">
          Our Services are offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Avlos's <a href="/privacy">Privacy Policy</a>) and procedures that may be published from time to time by Avlos (collectively, the "Agreement"). You agree that we may automatically upgrade our Services, and these Terms will apply to any upgrades.
        </p>
        <p class="text-sm pt-4">
          We refer to Avlos LLC collectively as "Avlos" or "we" throughout this
          agreement.
        </p>
        <p class="text-sm pt-4">
          Please read this Agreement carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to become bound by the Terms of this Agreement. If you do not agree to all the Terms of this Agreement, then you may not access or use any of our Services. If these Terms are considered an offer by Avlos, acceptance is expressly limited to these Terms.
        </p>
        <p class="text-sm pt-4"> Our Services are not directed to children. Access to and use of our Services is only for those over the age of 13 (or 16 in the European Union). If you are younger than this, you may not register for or use our Services. Any person who registers as a user or provides their personal information to our Services represents that they are 13 years of age or older (16 years or older in the European Union).
        </p>
      </div>
      <div class="col-span-9">
        <avl-expansion-panels toggle="false">
          <avl-expansion-panel header="1. Content Posted on Other Websites.">
            <div>
              <p class="text-sm mb-5 text-black">
                We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and webpages to which <a href="/">avlos.ai</a> links, and that link to <a href="/">avlos.ai</a>. Avlos does not have any control over those non-Avlos websites, and is not responsible for their contents or their use. By linking to a non-Avlos website, Avlos does not represent or imply that it endorses such website. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. Avlos disclaims any responsibility for any harm resulting from your use of non-Avlos websites and webpages.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="2. Copyright Infringement and DMCA Policy.">
            <div>
              <p class="text-sm mb-5 text-black">
                As Avlos asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by <a href="/">avlos.ai</a> violates your copyright, you are encouraged to <a href="/contact">notify</a> Avlos. Avlos will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. Avlos will terminate a visitor's access to and use of the website if, under appropriate circumstances, the visitor is determined to be a repeat infringer of the copyrights or other intellectual property rights of Avlos or others. In the case of such termination, Avlos will have no obligation to provide a refund of any amounts previously paid to Avlos.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="3. Intellectual Property.">
            <div>
              <p class="text-sm mb-5 text-black"> This Agreement does not transfer from Avlos to you any Avlos or third party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with Avlos. Avlos, <a href="/">avlos.ai</a>, the <a href="/">avlos.ai</a> logo, and all other trademarks, service marks, graphics and logos used in connection with <a href="/">avlos.ai</a> or our Services, are trademarks or registered trademarks of Avlos or Avlos's licensors. Other trademarks, service marks, graphics and logos used in connection with our Services may be the trademarks of other third parties. Your use of our Services grants you no right or license to reproduce or otherwise use any Avlos or third-party trademarks.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="4. Changes.">
            <div>
              <p class="text-sm mb-5 text-black">
                We are constantly updating our Services, and that means sometimes we have to change the legal terms under which our Services are offered. If we make changes, any dispute that arose before the changes shall be governed by the Terms (including the binding individual arbitration clause) that were in place when the dispute arose.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="5. Termination.">
            <div>
              <p class="text-sm mb-5 text-black">
                Avlos may terminate your access to all or any part of our Services at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate this Agreement, you may simply discontinue using our Services. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="6. Disclaimer of Warranties.">
            <div>
              <p class="text-sm mb-5 text-black"> Our Services are provided "as is." Avlos and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Avlos nor its suppliers and licensors, makes any warranty that our Services will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, our Services at your own discretion and risk. </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="7. Jurisdiction and Applicable Law.">
            <div>
              <p class="text-sm mb-5 text-black">
                Except to the extent applicable law, if any, provides otherwise, this Agreement, any access to or use of our Services will be governed by the laws of the state of Massachusetts, U.S.A., excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the state and federal courts located in Boston, Massachusetts. </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="8. Arbitration Agreement.">
            <div>
              <p class="text-sm mb-5 text-black">
                Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court without the posting of a bond), any dispute arising under this Agreement shall be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and Mediation Service, Inc. ("JAMS") by three arbitrators appointed in accordance with such Rules. The arbitration shall take place in Boston, Massachusetts, in the English language and the arbitral decision may be enforced in any court. The prevailing party in any action or proceeding to enforce this Agreement shall be entitled to costs and attorneys' fees.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="9. Limitation of Liability.">
            <div>
              <p class="text-sm mb-5 text-black">
                In no event will Avlos, or its suppliers or licensors, be liable with respect to any subject matter of this Agreement under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for interruption of use or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to Avlos under this Agreement during the twelve (12) month period prior to the cause of action. Avlos shall have no liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="10. General Representation and Warranty.">
            <div>
              <p class="text-sm mb-5 text-black">
                You represent and warrant that your use of our Services:
              </p>
              <check-list class="text-black" :list="[
                  'Will be in strict accordance with this Agreement;',
                  'Will comply with all applicable laws and regulations (including without limitation all applicable laws regarding online conduct and acceptable content, the transmission of technical data exported from the United States or the country in which you reside, privacy, and data protection); and',
                  'Will not infringe or misappropriate the intellectual property rights of any third party.',
                ]" />
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="11. US Economic Sanctions.">
            <div>
              <p class="text-sm mb-5 text-black">
                You expressly represent and warrant that your use of our Services and or associated services and products is not contrary to applicable U.S. Sanctions. Such use is prohibited, and Avlos reserves the right to terminate accounts or access of those in the event of a breach of this condition.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="12. Indemnification.">
            <div>
              <p class="text-sm mb-5 text-black">
                You agree to indemnify and hold harmless Avlos, its contractors, and its licensors, and their respective directors, officers, employees, and agents from and against any and all losses, liabilities, demands, damages, costs, claims and expenses, including attorneys' fees, arising out of or related to your use of our Services, including but not limited to your violation of this Agreement.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="13. Translation.">
            <div>
              <p class="text-sm mb-5 text-black">
                These Terms of Service were originally written in English (US). We may translate these terms into other languages. In the event of a conflict between a translated version of these Terms of Service and the English version, the English version will control.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="14. Miscellaneous.">
            <div>
              <p class="text-sm text-black">
                This Agreement constitutes the entire agreement between Avlos and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of Avlos, or by the posting by Avlos of a revised version.
              </p>
              <p class="text-sm pt-4 text-black">
                If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
              </p>
              <p class="text-sm pt-4 text-black mb-5">
                You may assign your rights under this Agreement to any party that consents to, and agrees to be bound by, its terms and conditions; Avlos may assign its rights under this Agreement without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.
              </p>
            </div>
          </avl-expansion-panel>
        </avl-expansion-panels>
      </div>
    </div>
  </div>
</template>