import ConfigMethods from '../assets/index.js'
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  methods: {
    getDescr(item_name) {
      var a = ConfigMethods.FEAT_ITEMS.filter(i => i.name === item_name)
      return a.empty ? "" : a[0].descr;
    },
    animateFrom(elem, direction) {
      direction = direction || 1;
      var x = 0,
        y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
        duration: 1,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "power2.inOut",
        overwrite: "auto"
      });
    },
    hide(elem) {
      gsap.set(elem, { autoAlpha: 0 });
    },
    batch(targets, vars) {
      let varsCopy = {},
        interval = vars.interval || 0.1,
        proxyCallback = (type, callback) => {
          let batch = [],
            delay = gsap.delayedCall(interval, () => { callback(batch);
              batch.length = 0; }).pause();
          return self => {
            batch.length || delay.restart(true);
            batch.push(self.trigger);
            vars.batchMax && vars.batchMax <= batch.length && delay.progress(1);
          };
        },
        p;
      for (p in vars) {
        varsCopy[p] = (~p.indexOf("Enter") || ~p.indexOf("Leave")) ? proxyCallback(p, vars[p]) : vars[p];
      }
      gsap.utils.toArray(targets).forEach(target => {
        let config = {};
        for (p in varsCopy) {
          config[p] = varsCopy[p];
        }
        config.trigger = target;
        ScrollTrigger.create(config);
      });
    },
    scrollBelow(elemId, btnId) {
      const element = document.getElementById(elemId);
      const btn = document.getElementById(btnId);
      window.scrollTo({left: 0, top:element.offsetHeight + btn.offsetHeight ,behavior: "smooth"})
    },
  }
}