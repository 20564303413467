<template>
  <div>
    <div :class="[
          line
            ? 'grid grid-cols-12 gap-1 border-b-2 border-grayscale-line mb-5'
            : 'grid grid-cols-12 gap-1',
          paddingTop ? 'py-12' : 'pb-12'
            ]
        ">
      <div class="col-span-12 md:col-span-8">
        <div class="text-avl-copper mb-0 text-2xl">
          {{ headerList }}
        </div>
        <div class="text-white text-lg max-w-md py-2">
          {{ subHeaderList }}
        </div>
      </div>
      <div class="col-span-12 md:col-span-4 text-lg">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerList: {
      type: String,
      required: true,
      default: null,
    },
    subHeaderList: {
      type: String,
      required: true,
      default: null,
    },
    line: {
      type: Boolean,
      required: false,
      default: true,
    },
    paddingTop: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
<style></style>