<template>
  <div class="bg-white">
    <!-- Header -->
    <div class="relative pb-32">
      <div class="absolute inset-0">
        <img class="h-full w-full object-cover" src="/img/avlos-summary-2.png" alt="" />
      </div>
      <div class="text-center relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-40">
        <h1 class="tracking-tight text-white text-2xl sm:text-3xl lg:text-4xl pb-10">Benefits to help your team thrive</h1>
        <p class="mx-auto max-w-lg text-base text-white">No matter where your team is located, we are equipped to help your team and product move forward with remote collaboration best practices.</p>
      </div>
    </div>
    <!-- Overlapping cards -->
    <section class="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-40" aria-labelledby="contact-heading">
      <h2 class="sr-only" id="contact-heading">Contact us</h2>
      <div class="grid grid-cols-1 gap-y-10 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        <div v-for="card in cardItems" :key="card.name" class="flex flex-col bg-black shadow-xl gs_reveal card">
          <div class="relative flex-1 px-6 pt-8 pb-8 md:px-8">
            <h3 class="text-md text-center uppercase text-white">{{ card.name }}</h3>
            <p class="mt-2 desktopTextX-Small text-white">{{ card.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import MixinContent from '@/mixins/Content.js';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  mixins: [MixinContent],
  props: {
    name: String,
    link: String,
    img: String,
    descr: String,
    heightStyle: String,
  },
  data() {
    return {
      cardItems: [{
          name: 'Mentoring',
          description: 'Our team brings great perspective in planning projects and an organized approach to development practices, collaborating and learning. Working side by side with other teams allows us to share these processes and activate great potential.',
        },
        {
          name: 'Deploy and maintain',
          description: 'Our mission is to leave you in a place when you can confidently maintain, test and improve your product. If things go well, your product will never be complete and we want to set strong technical foundations for the teams that will take over its development after out engagement has concluded.',
        },
        {
          name: 'Reproducibility',
          description: 'We implement infrastructure as code to ensure long term maintainability and automate steps of the deployment and infrastructure provisioning processes so your team does not have to be on call. Comprehensive documentation is also included with any new code and automation development.',
        },
      ]
    }
  },
  mounted() {
    let self = this;
    self.batch(".card", {
      interval: 0.5,
      batchMax: 3,
      onEnter: batch => gsap.to(batch, { autoAlpha: 1, stagger: 0.15, overwrite: true }),
      once: true
    });
  },
};
</script>