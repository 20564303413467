<template>
  <div id="landingPage" class="bg-black font-geo">
    <div class="mx-auto max-w-full h-[95vh] xl:h-auto lg:pt-4 2xl:pt-16">
      <div class="relative text-center py-24 2xl:py-10 sm:pt-0">
        <div class="absolute inset-0 flex justify-center">
          <img id="headerImg" class="w-full object-cover" src="/img/avlos-header-home.png" alt="Background image" />
          <img class="m-auto h-[65%] opacity-5 absolute top-24 fade-in-5" src="/img/avl-symbol-white.png" alt="Avlos LLC symbol" />
        </div>
        <div class="relative mx-auto max-w-7xl py-24 sm:py-52 2xl:py-96 justify-center">
          <h1 class="tracking-tight text-avl-copper text-3xl lg:text-5xl">
            We are Avlos
          </h1>
          <p class="mt-10 max-w-xl mx-auto text-lg sm:text-xl text-white fade-in">
            Specializing in building software
            <a class="cursor-pointer text-white hover:text-avl-copper" @click="redirect('/enterprises')">
              for enterprise organizations
            </a>
            and
            <a class="cursor-pointer text-white hover:text-avl-copper" @click="redirect('/startups')">
              applications for startups.
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="bg-grayscale-off-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pt-[50px] pb-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-3xl lg:text-4xl pb-10 text-avl-bg-grey">
            Services
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1" v-for="item in serviceItems" :key="item">
          <CardLink :name="item.name" :img="item.img" :link="item.link" :descr="item.descr" heightStyle="h-[50vh] max-h-[450px]" />
        </div>
      </div>
    </div>
    <!-- <div class="bg-white"> -->
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 pt-[90px] pb-[90px] text-white">
      <p class="mt-2 sm:max-w-3xl text-lg">
        Whether you’re a founder building something from scratch, a product owner at an enterprise improving an existing service, or somewhere in between, we have you covered.
      </p>
      <p class="mt-4 sm:max-w-3xl text-lg">
        Our approach is highly collaborative and our experienced team of software engineers and data scientists operate as an extension of yours to enhance the speed and quality of your project.
      </p>
    </div>
    <div class="bg-grayscale-off-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pt-[50px] pb-[90px]">
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-[184px]" />
        </div>
      </div>
    </div>
    <div class="">
    <div class="mx-auto max-w-full grid grid-cols-2 gap-0">
      <div class="col-span-2 sm:col-span-1 bg-avl-copper">
        <div class="group flex justify-center items-center relative bg-avl-copper hover:bg-orange-700 cursor-pointer text-4xl">
          <a href="/startups" class="text-center items-center h-full w-full">
            <h1 class="text-white py-6 sm:py-6 2xl:py-8 text-2xl lg:text-3xl">Boost your startup</h1>
          </a>
          <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 gs_shine hidden sm:block" />
        </div>
      </div>
      <div class="col-span-2 sm:col-span-1 bg-black hover:bg-zinc-900 ">
        <a href="/enterprises" class="text-center items-center h-full w-full">
          <h1 class="text-white py-6 sm:py-6 2xl:py-8 text-2xl lg:text-3xl">Empower your enterprise</h1>
        </a>
      </div>
    </div>
  </div>
    <div class="mx-auto max-w-full">
      <div class="relative">
        <div class="absolute inset-0 flex justify-end">
          <img class="h-full w-full object-cover" src="/img/avlos-summary-1.png" alt="" />
          <img class="h-full object-cover opacity-10 absolute" src="/img/avl-symbol-copper-bg.png" alt="" />
        </div>
        <div class="relative mx-auto max-w-4xl py-20 sm:py-40 2xl:py-80 grid grid-cols-2 px-4 sm:px-0">
          <div class="hidden sm:block sm:col-span-1 row-span-1">
            <img class="h-full sm:w-5/6 object-cover img-shadow" src="/img/card-link-5.png" alt="" />
          </div>
          <div class="col-span-2 sm:pt-2 sm:col-span-1 row-span-1">
            <h1 class="tracking-tight text-white text-3xl lg:text-4xl">
              Solve your toughest business challenges.
            </h1>
            <p class="mt-10 sm:max-w-3xl text-base text-white">We tackle challenging problems in software engineering and data science working with organizations across a range of sectors.</p>
            <p class="mt-5 sm:max-w-3xl text-base text-white">The Avlos team offers a combination of deep industry knowledge, world-class technical expertise, and a constant drive to learn and apply innovation towards practical applications. Our goal is to turn ambitious, bold, creative ideas into reality.</p>
            <div class="mt-5 flex items-center">
              <a href="/company">
                <button type="button" class="relative inline-flex items-center border border-transparent bg-avl-copper px-2 sm:px-8 py-2 text-base font-medium shadow-sm hover:bg-orange-700">
                  <span>Meet the Avlos community</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardLink from "@/components/CardLink.vue";
import FeatCard from "@/components/FeatCard.vue";
import MixinContent from '@/mixins/Content.js';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "LandingPage",
  mixins: [MixinContent],
  components: {
    CardLink,
    FeatCard,
  },
  data() {
    return {
      tabList: ["Technology Consulting", "Web & Mobile", "Data & Analytics"],
      serviceItems: [
        { name: "Technology Consulting", img: "/img/card-link-4.png", link: "/consulting", descr: "Up performance and cut infrastructure costs with our DevOps services." },
        { name: "Web and Mobile", img: "/img/card-link-2.png", link: "/development", descr: "Plan, design, develop and launch custom software." },
        { name: "Data and Analytics", img: "/img/card-link-1.png", link: "/analytics", descr: "Turn your data into value." },
      ],
      srvcsItems: [{
          id: "data_analytics",
          name: "data & analytics",
          pts: [{
              name: "Connect insights to results.",
              descr: "Understand your data to better evaluate the performance of your products and more."
            },
            {
              name: "Make your data work for you.",
              descr: "Access unique insights and machine learning capabilities to get more value from data."
            },
          ],
          url: "/analytics",
          img: "/img/page-preview-section-1.png",
          current: true,
        },
        {
          id: "technology_consulting",
          name: "technology consulting",
          pts: [{
              name: "Deploy your entire tech stack.",
              descr: "Customize your tech stack to meet your needs and deploy using infrastructure code developed by DevOps experts."
            },
            {
              name: "Get expert support and training.",
              descr: "Learn from our engineers and get support on questions, troubleshooting, best practices, and maintenance."
            },
            {
              name: "Achieve compliance.",
              descr: "Establish secure infrastructure and achieve compliance with PCI, HIPAA, GDPR and other standards."
            },
            {
              name: "Decrease operational costs.",
              descr: "Use automation to reduce provision costs, enable your team to leverage time more effectively and optimize infrastructure operation fees."
            },
          ],
          url: "/consulting",
          img: "/img/page-preview-section-2.png",
          current: false,
        },
        {
          id: "web_and_mobile",
          name: "web and mobile",
          pts: [{
              name: "Offer engaging user experiences.",
              descr: "We pay close attention to flows and we collaborate with you to design experiences that build successful products and businesses."
            },
            {
              name: "Publish to the app/play stores.",
              descr: "If you have a mobile app idea you want to make reality, or an existing iOS or Android app you want to improve, we’re here to help."
            },
            {
              name: "Utilize best tools for your application.",
              descr: "While we have rich history with specific frameworks, we are comfortable using a range of technologies based on your project requirements."
            },
          ],
          url: "/development",
          img: "/img/page-preview-section-3.png",
          current: false,
        },
      ],
      feats: [
        "software engineering", "product management", "IT architecture", "data engineering", "enterprise solutions", "security"
      ],
    }
  },
  mounted() {
    gsap.utils.toArray(".gs_shine").forEach(function(elem) {
      ScrollTrigger.create({
        trigger: elem,
        toggleClass: "animate-shine"
      });
    });
  },
};
</script>
<style lang="scss">
</style>