<template>
  <div>
    <div v-for="(item, index) in list" :key="`${index}-${item}`" class="grid grid-flow-row-dense grid-cols-12 justify-items-stretch items-start pb-1">
      <div class="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 justify-self-end">
        <img class="w-[15px] pt-2" src="/img/check.svg" alt="check" />
      </div>
      <div class="col-span-11 sm:col-span-11 md:col-span-10 lg:col-span-11 pl-4">
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: null,
    },
  },
};
</script>
<style lang="scss"></style>