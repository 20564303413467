<template>
  <div id="webMobileAppDevelopment" class="bg-black font-geo">
    <PageHeader heading="Avlos technology consulting" subheading="Up performance and cut infrastructure costs with our DevOps services." img="/img/avlos-header-consulting.png" />
    <div class="bg-grayscale-off-white">
      <div class="grid grid-cols-9 gap-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 sm:pb-[90px] pt-[90px]">
        <div class="col-span-9 border-b-2 border-grayscale-line"></div>
        <div class="col-span-9 md:col-span-4">
          <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-10">
            Deploy your entire tech stack.
          </p>
          <p class="text-base">
            Customize your tech stack to support your business needs and deploy using infrastructure code developed by DevOps experts.
          </p>
        </div>
        <div class="col-span-9 md:col-span-5 sm:pl-12 sm:pt-32">
          <avl-expansion-panels toggle="false">
            <avl-expansion-panel header="APPLICATION AUDIT">
              <div>
                <p class="text-base mb-5 text-black">
                  We understand the state of your application, existing architecture and opportunities for optimization. A common result from this engagement is a step by step plan for upgrading your system.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="DEVOPS PRACTICES">
              <div>
                <p class="text-base mb-5 text-black">
                  We help you choose DevOps solutions that will have the most impact in your development and release cycle:
                </p>
                <check-list class="text-black mb-5 " :list="[
                  'Continuous integration',
                  'Continuous delivery',
                  'Continuous deployment',
                  'Configuration management',
                  'Load testing',
                  'Performance monitoring',
                  'Availability monitoring',
                  'Log aggregation',
                  'Alerting',
                  'Automated recovery',
                  'Auto scaling',
                ]" />
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="CONTAINERIZE">
              <div>
                <p class="text-base mb-5 text-black">
                  We bundle up your application, its dependencies, and its configuration into a single package. We often recommend containers when our customers are looking for solutions to reduce operational overhead and management.
                </p>
                <p class="text-base mb-5 text-black">
                  This process ensures that your application can run consistently across on premises and cloud environments, so that you have the
                  flexibility to work on either.
                </p>
                <p class="text-base mb-5 text-black">
                  Starting up your application and scaling becomes a much less
                  daunting tasks for developers and for customers looking to
                  modernize applications, containerizing using a microservices
                  architercture so that different components can be worked on and
                  scaled independently can speed up the delivery of enhancements.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="INFRASTRUCTURE AS CODE">
              <div>
                <p class="text-base mb-5 text-black">
                  We use IaC to free developers and system administrators from
                  manual processes related to configuring operating systems and
                  system applications and set a framework for long term
                  maintainability.
                </p>
                <p class="text-base mb-5 text-black">
                  IaC also helps us enforce monitoring and compliance at scale for
                  our customers.
                </p>
              </div>
            </avl-expansion-panel>
          </avl-expansion-panels>
        </div>
        <div class="col-span-9 md:col-span-4">
          <div class="text-2xl sm:text-3xl lg:text-4xl text-black mb-10">
            Get expert support and training.
          </div>
          <p class="text-base">
            Learn from our engineers and get support on questions, troubleshooting, best practices, and maintenance.
          </p>
        </div>
        <div class="col-span-9 border-b-2 border-grayscale-line"></div>
        <div class="col-span-9 md:col-span-4">
          <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-10">
            Achieve compliance.
          </p>
          <p class="text-base">
            If you're looking to achieve compliance with HIPPA, GDPR, PCI DSS, another standard or just hit some security benchmarks, we're here to help.
          </p>
        </div>
        <div class="col-span-9 md:col-span-5 sm:pl-12 sm:pt-32">
          <avl-expansion-panels toggle="false">
            <avl-expansion-panel header="IDENTITY AND ACCESS MANAGEMENT">
              <div>
                <p class="text-base mb-5 text-black">
                  We help you incorporate modern identity and access management solutions to design a secure digital experience for your product's users. We also provision user access and activity within your organization and help set safeguards to avoid misuse of priviledges.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="STORAGE">
              <div>
                <p class="text-base mb-5 text-black">
                  We carefully review processes around in-transit and at-rest encryption, access control to the resources, and handling sensitive data to satisfy compliance regulations in your sector or to simply create a record for future auditing and reconciliation plans.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="LOGGING">
              <div>
                <p class="text-base mb-5 text-black">
                  Our team has experience implementing a variety of log ingestion and log querying solutions around user activity, API usage, network traffic and more auditing features to meet the needs of your business.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="MONITORING">
              <div>
                <p class="text-base mb-5 text-black">
                  Monitoring within the context of compliance can mean a lot of things but our team is equipped to help you reduce risk by setting up services that can detect security configuration changes and events across your system.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="NETWORKING">
              <div>
                <p class="text-base mb-5 text-black">
                  We can help you move away from manual network configuration checks to handling network governance fearlessly with a continuous verification of network compliance solution.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="PASS AN AUDIT">
              <div>
                <p class="text-base mb-5 text-black">
                  Let our team inspect your accounts for compliance to a standard by developing a risk assessment plan and conducting internal and external security testing of your product. A common output from this engagement is a security risk analysis that discloses system vulnerabilties and potential threats.
                </p>
              </div>
            </avl-expansion-panel>
            <avl-expansion-panel header="DEVELOPMENT PLAN">
              <div>
                <p class="text-base mb-5 text-black">
                  We put together a step by step security and vulnerability management plan to address your most risks and conform to regulations as well as documentation on technical recommendations to reinforce your security strategies.
                </p>
              </div>
            </avl-expansion-panel>
          </avl-expansion-panels>
        </div>
        <div class="col-span-9 md:col-span-4 pb-10 md:pb-5">
          <div class="text-2xl sm:text-3xl lg:text-4xl text-black pb-10">
            Decrease operational costs.
          </div>
          <p class="text-base mb-10">
            Use automation to reduce provision costs, enable your team to leverage time more effectively and optimize infrastructure operation fees.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pb-[90px] pt-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-2xl sm:text-3xl lg:text-4xl pb-10">
            Service Highlights
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-0" />
        </div>
      </div>
    </div>
    <next-step />
  </div>
</template>
<script>
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import PageHeader from "@/components/PageHeader.vue";
import NextStep from "@/components/NextStep.vue";
import CheckList from "@/components/CheckList.vue";
import MixinContent from '@/mixins/Content.js';
import FeatCard from "@/components/FeatCard.vue";

export default {
  name: "TechnologyConsulting",
  mixins: [MixinContent],
  components: {
    PageHeader,
    FeatCard,
    NextStep,
    CheckList,
  },
  data() {
    return {
      feats: ["infrastructure modernization", "databases", "application scaling", "media", "financial services", "IoT"
      ],
    }
  },
  mounted() {
    // TODO: extract
    let self=this
    gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
      self.hide(elem);
      ScrollTrigger.create({
        trigger: elem,
        onEnter: function() { self.animateFrom(elem) },
        once: true
      });
    });
  },
};
</script>
<style lang="scss">
@import "@/styles/global.scss";
</style>