const FEAT_ITEMS = [{
    name: "software engineering",
    descr: "Our developers have expertise working with the whole web stack to build value-generating products and services."
  },
  {
    name: "product management",
    descr: "We bring together business, design, and technology to define, build, and enable digital products throughout their lifecycle."
  },
  {
    name: "IT architecture",
    descr: "We architect and support next generation platforms, optimizing processes, and overcoming limitations in legacy systems."
  },
  {
    name: "data engineering",
    descr: "We build back-end applications with data services, cloud deployment, server-side & server-less architecture and provide expertise in security, scalability and performance."
  },
  {
    name: "enterprise solutions",
    descr: "We provide an independent view with a value-driven focus to mitigate implementation risk and define solutions at scale."
  },
  {
    name: "security",
    descr: "We deliver comprehensive strategies and solutions by securing assets, streamlining costs and optimizing cyber investments."
  },
  {
    name: "business application platform",
    descr: "Extend legacy applications using APIs to integrate existing and new software for application development."
  },
  {
    name: "productivity & collaboration",
    descr: "Minimize manual workflows  and accelerate team collaboration."
  },
  {
    name: "healthcare & life sciences",
    descr: "Pair medical devices with reliable platforms for diagnostics and acute care in real clinical environments."
  },
  {
    name: "media",
    descr: "Enhance your asset management strategy and make your content available to the world."
  },
  {
    name: "telecommunications",
    descr: "Data driven decisions to manage network optimizations and costumer experience."
  },
  {
    name: "financial services",
    descr: "Drive business growth while meeting customer expectations, security and compliance needs."
  },
  {
    name: "COVID-19 industry solutions",
    descr: "Move forward with innovative business continuity and operational efficiency solutions."
  },
  {
    name: "government",
    descr: "Identify threats and opportunities and get help in  rapid prototyping and full system development."
  },
  {
    name: "education",
    descr: "Develop edtech tools that support teaching and learning in a changing environment."
  },
  {
    name: "application scaling",
    descr: "Configure system architecture to maintain quality and performance as your audience grows."
  },
  {
    name: "AI & machine learning",
    descr: "Explore how AI & ML can redifine workflow processes and create new consumer experiences."
  },
  {
    name: "agtech",
    descr: "Drive the next generation of farming with applications that are focused on health and quality."
  },
  {
    name: "sustainability",
    descr: "Advance applications that drive collaboration to improve environmental, human and animal health."
  },
  {
    name: "IoT",
    descr: "Connect, store and analyze data from your device network with an intelligent IoT platform."
  },
  {
    name: "databases",
    descr: "Securely and cost effectively store and process data to support powerful applications."
  },
  {
    name: "infrastructure modernization",
    descr: "Level up performance, management and availability of your applications."
  }
]

export default {
  FEAT_ITEMS: FEAT_ITEMS
}