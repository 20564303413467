<template>
  <div id="AboutCompany" class="bg-black font-geo">
    <PageHeader heading="Get started with Avlos" subheading="A dynamic team of software engineers collaborating closely with clients" img="/img/avlos-header-company.png" />
    <div class="bg-white py-12 sm:py-[90px]">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-1 gap-2 pb-5">
        <div class="col-span-1">
          <h1 class="tracking-tight text-3xl lg:text-4xl pb-10">
            Our Story
          </h1>
          <p class="pb-5 text-base">
            Avlos started out as a software services company that provides custom technology solutions to businesses in 2018. We began working together to create a better way for startups and enterprises to engage with lean, highly technical, research orientated teams to achieve some amazing results.
          </p>
          <p class="pb-5 text-base">
            After working with a number of clients and building up a library of reusable scripts, templates, and best practices, we’re in a position to take an idea from design to production while helping you avoid common pitfalls, address your performance concerns and prepare to scale.
          </p>
          <p class="text-base">
            We are a team that is passionate about software and we take a lot of pride in our craft but at our core we are problem solvers that will get things done, always optimizing for faster feedback and maximum value added to our clients.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-grayscale-off-white">
      <div id="offices" class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40">
        <h1 class="tracking-tight text-white text-3xl lg:text-4xl py-5 absolute z-50">
          Offices
        </h1>
      </div>
      <AboutMap />
    </div>
    <next-step />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import NextStep from "@/components/NextStep.vue";
import AboutMap from "@/components/AboutMap.vue";

export default {
  name: "AboutCompany",
  components: {
    PageHeader,
    NextStep,
    AboutMap,
  },
};
</script>
<style lang="scss">
#offices:after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 5em solid rgba(252, 252, 252, 0.2);
  position: absolute;
  right: 0;
  margin: 0em auto 0;
}
</style>