<template>
  <div id="nextStep" class="relative">
    <div class="bg-avl-dark-blue py-16 text-center">
      <div class="text-2xl sm:text-3xl lg:text-4xl text-white">
        Take the next step
      </div>
      <div class="my-5 desktopTextMedium text-white mx-auto max-w-xs">
        Tell us what the stage of your project is, we will help you find the
        best solution.
      </div>
      <a href="/contact">
        <button id="nextStepsBtn" type="button" class="items-center bg-avl-copper  px-8 py-2 text-base font-medium shadow-sm relative ">
          <span>Contact Us</span>
        </button>
      </a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
@import "@/styles/global.scss";

#nextStep:before {
  content: "";
  display: block;
  width: 25%;
  border-bottom: 0.5em solid #E85700;
  position: absolute;
  left: 0;
  margin: auto -0.5em 0;

}

#nextStep:after {
  content: "";
  display: block;
  width: 25%;
  border-bottom: 0.5em solid #E85700;
  position: absolute;
  right: 0;
  margin: -0.5em auto 0;
}
</style>