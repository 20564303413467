<template>
  <div id="ContactForm" class="bg-white font-geo">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-2 gap-10 pt-12 sm:pt-[90px] sm:pb-[90px]">
      <div class="col-span-2 sm:col-span-1 row-span-1 flex items-center">
        <div>
          <h1 class="tracking-tight text-avl-copper text-3xl lg:text-4xl">
            Get Started with Avlos
          </h1>
          <p class="mt-8 max-w-2xl mx-auto text-lg sm:text-2xl text-black">
            Fill out the form, including details about your next project (or business goals), and we’ll be in touch shortly.
          </p>
        </div>
      </div>
      <div class="hidden sm:block sm:col-span-1 row-span-1 sm:flex justify-end bg-zinc-900 px-24 img-shadow">
        <svg xmlns="http://www.w3.org/2000/svg" width="1007.327" height="948.277" viewBox="0 0 1007.327 948.277" class="max-h-[500px] ">
          <g id="Group_2518" data-name="Group 2518" transform="translate(0.813 0.5)" style="isolation: isolate">
            <path class="svg-fill" id="Path_757" data-name="Path 757" d="M217.958,0,28.343.095l479.1,947.18h189.7Z" transform="translate(308.56 0)" />
            <path class="line-1" id="Path_757_-_Outline" data-name="Path 757 - Outline" d="M218.265-.5l.139.274,479.55,948H507.136L507,947.5,27.53-.4h.813ZM696.327,946.775,217.65.5,29.156.594,507.75,946.775Z" transform="translate(308.56 0)" />
            <path class="svg-fill" id="Path_758" data-name="Path 758" d="M296.213,30.427,0,616.03H189.71l296.213-585.6Z" transform="translate(0 331.247)" />
            <path class="line-2" id="Path_758_-_Outline" data-name="Path 758 - Outline" d="M295.906,29.927h190.83l-.367.726L190.017,616.53H-.813l.367-.726Zm189.2,1H296.521L.813,615.53H189.4Z" transform="translate(0 331.247)" />
            <path class="svg-fill" id="Path_759" data-name="Path 759" d="M32.246,55.06,180.4,347.862H370.1L221.944,55.06Z" transform="translate(351.048 599.416)" />
            <path class="line-3" id="Path_759_-_Outline" data-name="Path 759 - Outline" d="M31.433,54.56H222.251l.139.274L370.911,348.362H180.093l-.139-.274Zm190.2,1H33.059l147.648,291.8H369.284Z" transform="translate(351.048 599.416)" />
          </g>
        </svg>
      </div>
    </div>
    <form action="https://formkeep.com/f/5e6b9ec7ffff" accept-charset="UTF-8" enctype="multipart/form-data" method="POST">
      <input type="hidden" name="utf8" value="✓">
      <input type="hidden" name="subscribe_5e6b9ec7ffff_40561" value="">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-7 gap-2 pb-20 sm:pb-[90px]">
        <div class="hidden sm:block sm:col-span-2 text-3xl">Your info</div>
        <div class="col-span-7 sm:col-span-5 max-w-md">
          <div class="pt-16 pb-5">
            <label for="contact-name">Name *</label>
            <input type="text" name="name" id="contact-name" value="" autocomplete="on" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" required />
          </div>
          <div class="pb-5">
            <label for="contact-email">Email *</label>
            <input type="email" name="email" id="contact-email" value="" autocomplete="on" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" required />
          </div>
          <div class="pb-5">
            <label for="contact-phone">Phone</label>
            <input type="tel" name="phone" id="contact-phone" value="" autocomplete="on" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
          </div>
          <div class="pb-5">
            <label for="contact-info">How did you hear about Avlos?</label>
            <input type="text" name="info" id="contact-info" size="25" value="" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
          </div>
        </div>
        <div class="hidden sm:block sm:col-span-2 text-3xl">Your project</div>
        <div class="col-span-7 sm:col-span-5 max-w-md">
          <div class="pt-16 pb-5">
            <label for="contact-company">Company or project name *</label>
            <input type="text" name="company" id="contact-company" size="25" value="" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" required />
          </div>
          <div class="pb-5">
            <div class="mergeRow dojoDndItem mergeRow-dropdown" id="mergeRow-7">
              <div class="field-group">
                <label for="contact-service">What can Avlos do for you?</label>
                <select class="select-small mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" name="service" id="contact-service">
                  <option></option>
                  <option value="Create an amazing new product">Create an amazing new product</option>
                  <option value="Make my product even better">Make my product even better</option>
                  <option value="Help with my app maintenance and servers">Help with my app maintenance and servers</option>
                  <option value="Something else">Something else</option>
                </select>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <label class="pt-16" for="contact-project">Can you tell us a bit more about that? *</label>
            <textarea type="text" name="project" id="contact-project" size="25" value="" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" rows="5" required></textarea>
            <span class="text-avl-dark-grey text-sm">If you need an NDA prior to talking to us, feel free to ask us for one or send one along by contacting us at info@avlos.ai</span>
          </div>
          <div class="pb-5">
            <label for="contact-budget">What is your budget?</label>
            <select class="select-small mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" id="contact-budget" name="budget">
              <option></option>
              <option value="Less than $50,000">Less than $50,000</option>
              <option value="$50,000 - $150,000">$50,000 - $150,000</option>
              <option value="$150,000 - $350,000">$150,000 - $350,000</option>
              <option value="$350,000 or more">$350,000 or more</option>
              <option value="To be determined">To be determined</option>
            </select>
          </div>
        </div>
        <div class="hidden sm:block sm:col-span-2 sm:col-offset-2 max-w-md"></div>
        <div class="col-span-7 sm:col-span-5 sm:col-offset-2 max-w-md">
          <div class="pt-16 pb-5">
            <button type="submit" class="relative inline-flex items-center border border-transparent bg-avl-copper px-8 py-2 text-base font-medium shadow-sm hover:bg-orange-700">
              <FontAwesomeIcon icon="fa-sharp fa-solid fa-spinner" class="-ml-1 mr-3 h-5 w-5 hidden" aria-hidden="true" />
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/sharp-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faSpinner);
export default {
  name: "AvlosCareers",
  components: {
    FontAwesomeIcon,
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/global.scss";

.line-1 {
  stroke-dasharray: 4000;
  stroke-dashoffset: 4000;
  animation: draw-stroke 2s linear forwards;
}

.line-2 {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: draw-stroke 2s linear forwards;
}

.line-3 {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  animation: draw-stroke 2s linear forwards;
}

.line-1,
.line-2,
.line-3 {
  fill: none;
  stroke: #E85700;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: miter;

}

@keyframes draw-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.svg-fill {
  opacity: 0;
  fill: #E85700;
  animation: draw-fill 4s linear forwards;
}

@keyframes draw-fill {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}
</style>