<template>
  <div id="webMobileAppDevelopment" class="bg-black font-geo">
    <PageHeader heading="Avlos web & mobile app development" subheading="Plan, design, develop and launch a sucessful web or mobile application." img="/img/avlos-header-development.png" />
    <div class="bg-white py-[90px]">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-2 gap-2 pb-10" v-for="item in webItems" :key="item.name">
        <div class="col-span-2 sm:col-span-1 text-2xl sm:text-3xl lg:text-4xl max-w-sm">
          <div class="">{{item.name}}</div>
        </div>
        <div class="col-span-2 md:col-span-1 border-b-2 border-grayscale-line pb-5">
          <p class="text-md mb-5" v-for="(pt, i) in item.pts" :key="item.name + i">
            {{ pt }}
          </p>
        </div>
      </div>
    </div>
    <div class="bg-grayscale-off-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pb-[90px] pt-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-2xl sm:text-3xl lg:text-4xl pb-10">
            Service Highlights
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-0" />
        </div>
      </div>
    </div>
    <next-step />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import NextStep from "@/components/NextStep.vue";
import FeatCard from "@/components/FeatCard.vue";
import MixinContent from '@/mixins/Content.js';

export default {
  name: "WebMobileAppDevelopment",
  mixins: [MixinContent],
  components: {
    PageHeader,
    NextStep,
    FeatCard,
  },
  data() {
    return {
      webItems: [{
          name: "Develop your specification",
          pts: [
            "We recommend scoping sessions to all our customers because it has a major impact on the outcome of the products we work on. Scoping allows us to understand and design your business idea better and helps you plan your budget and make sure it's the direction you really want to go.",
            "Our team of experts, including developers and a project manager, go through all aspects of project planning with you to develop a roadmap that is translated into a concrete technical action plan with expert recommendations for the development of your product idea."
          ]
        },
        {
          name: "Data modeling",
          pts: [
            "We define a data model that represents the types of data needed and stored in the system, the links between them, how the data can be categorized and arranged, and its formats and features. It is the basis for developing a high-quality product with an efficient data structure.",
          ]
        },
        {
          name: "Offer engaging user experiences",
          pts: [
            "We use your brand, UI concepts and design assets to develop complete sets of UX prototypes that form the basis of your product's design and guide to development.",
            "We pay close attention to flows and we collaborate with you to analyze patterns of user behavior, end users’ usability expectations, competitors’ UX solutions, and market best practices."
          ]
        },
        {
          name: "Utilize the best tools for your application",
          pts: [
            "While we have rich history with specific frameworks for getting applications running in the shortest amount of time, we are comfortable using a range of trusted, stable, proven technologies based on your project requirements. Our approach ultimately prioritizes developing features you need without weeks of research.",
            "Ruby on Rails is our choice of framework to get cleanly coded products in the hands of users as fast as possible and to set you up for scalability with a tech stack your future engineering team will love working on.",
            "The Django REST framework is one of our go to tools for developing APIs. Its power and incredible versatility allow us to use Django for a range of applications, including complex data analysis and machine learning products.",
            "We leverage progressive JavaScript frameworks, used by some of the most successful and biggest companies worldwide, like Vue.js and React.js to build web and hybrid mobile apps. Our choice ultimately is based on the option that will let us launch products quickly without sacrificing any of the looks and feel or UX."
          ]
        },
        {
          name: "Publish and manage",
          pts: [
            "Get support from a team of DevOps experts to manage your product releases and infrastructure. When it comes to infrastructure, we focus on automation from the get go. We use cost-effective automated solutions to help you save money, enhance security, reduce downtime, and improve your team's development and release workflows.",
            "All of the above are key to user experience and ensure that developers working on your product have an easy time improving and adding new features. With our approach to infrastructure management, you can have complete control over every aspect of your system as well as the resources to condifently maintain, test, assess and make future decisions. Our mission is to set you and your future team up for success.",
          ]
        }
      ],
      feats: [
        "application scaling", "AI & machine learning", "media", "financial services", "IoT", "productivity & collaboration"
      ],
    }
  }
};
</script>
<style></style>