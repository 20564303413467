<template>
  <div id="page-header" class="relative mx-auto max-w-full h-[95vh] xl:h-auto lg:pt-4 2xl:pt-16">
    <div class="relative text-center py-24 sm:pt-0">
      <div class="absolute inset-0 flex justify-center">
        <img id="headerImg" class="h-full w-full object-cover" :src="img" alt="Background image" />
        <img class="m-auto h-3/4 opacity-5 absolute top-24" src="/img/avl-symbol-white.png" alt="Avlos LLC symbol" />
      </div>
      <div class="relative mx-auto max-w-7xl py-24 sm:py-52 2xl:py-96 justify-center">
        <h1 class="mt-14 max-w-2xl mx-auto tracking-tight text-avl-copper text-2xl sm:text-3xl lg:text-4xl">
          {{subheading}}
        </h1>
      </div>
    </div>
    <button id="scroll-down-btn" @click="scrollBelow('page-header','scroll-down-btn')" type="button" class="flex flex-col items-center rounded-sm border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm absolute bottom-0 right-16 animate-bounce">
      Scroll
      <FontAwesomeIcon icon="fa-thin fa-circle-chevron-down " class="pt-3 h-10 w-10" aria-hidden="true" inverse/>
    </button>
  </div>
</template>
<script>
import MixinContent from '@/mixins/Content.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleChevronDown } from '@fortawesome/pro-thin-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faCircleChevronDown)

export default {
  mixins: [MixinContent],
  props: {
    heading: String,
    subheading: String,
    img: String,
  },
  components: {
    FontAwesomeIcon,
  }
};
</script>
<style lang="scss">
@import "@/styles/global.scss";
</style>