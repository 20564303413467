<template>
  <div id="forstartups" class="bg-black font-geo">
    <PageHeader heading="Avlos for startups" subheading="Intelligent, cost-effective, proven technology that helps you move fast." img="/img/avlos-header-startups.png" />
    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 pt-[90px] pb-[90px]">
        <avl-expansion-panels toggle="false">
          <avl-expansion-panel header="Quickly validate whether or not to advance to an MVP.">
            <div>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                Use a product prototype to get valuable feedback from users early
                in a project and make a great pitch.
              </p>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                You can get a refined prototype in about 2 months with limited
                resources in one of two ways. The first option is to create an
                interactive prototype to validate the strategic design direction
                of a product. The second option is to develop a proof of concept
                that can be used to demonstrate the core product functionality
                along with design.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="Navigate design and development for your complex MVP or v1 product.">
            <div>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                Products that stay out of users hands for too long are rarely
                aligned with their needs. If you need a product in its most
                simplified, get-the-job-done version to validate your riskiest
                product assumptions and define next iterations, think of building
                a Minimum Viable Product (MVP).
              </p>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                Our process starts by understanding all the assumptions you want
                to test and helping you define a scope that's adjusted to your
                target timeframe and budget. We spent a little time defining your
                product's visual design (if applicable) and kick off development.
                We choose tools for the job based on what you're trying to build
                and in writing code, we use a test driven development approach.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="Scale your product and team while maintaining and accelerating momentum.">
            <div>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                We start by outlining the details of the business goals you are
                working towards and we determine whether you are looking to
                increase performance with existing resources or you want to
                increase the amount of resources available to your application. We
                review your technology stack to identify bottlenecks, set
                performance metrics around them then start making changes.
              </p>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                In some cases all that's required to meet scaling goals is
                performance tuning with small code changes to get more out
                existing infrastructure setup. If your application's code has
                reached a point where it's as efficient as can be and throwing
                more hardware to the problem has become prohibitively expensive,
                we work with you to revisit the architecture of the application.
              </p>
            </div>
          </avl-expansion-panel>
          <avl-expansion-panel header="DevOps, site reliability and maintenance to unblock your team.">
            <div>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                New features and stable systems free from outages should not come
                at each other's cost. Our SRE and DevOps experts can help you
                manage your service objectives while maintaining a rapid
                development pace.
              </p>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                We work closely with your team to identify repetitive, time
                consuming, manual steps from build to deploy that can be automated
                to free up more time to innovate.
              </p>
              <p class="desktopTextMedium mb-5 text-black sm:pr-36">
                For significant reliability and operations needs, you can get on
                demand support and help to configure your infrastructure to abide
                local and global regulations, identify and handle security
                vulnerabilities while ensuring that your product is always
                available.
              </p>
            </div>
          </avl-expansion-panel>
        </avl-expansion-panels>
      </div>
    </div>
    <TeamBenefits />
    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pb-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-2xl sm:text-3xl lg:text-4xl pb-10">
            Service Highlights
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-0" />
        </div>
      </div>
    </div>
    <next-step />
  </div>
</template>
<script>
import NextStep from "@/components/NextStep.vue";
import PageHeader from "@/components/PageHeader.vue";
import TeamBenefits from "@/components/TeamBenefits.vue";
import FeatCard from "@/components/FeatCard.vue";
import MixinContent from '@/mixins/Content.js';

export default {
  name: "ForStartUps",
  mixins: [MixinContent],
  components: {
    PageHeader,
    TeamBenefits,
    NextStep,
    FeatCard,
  },
  data() {
    return {
      feats: ["application scaling", "media", "financial services", "IoT", "productivity & collaboration", "databases"
      ],
    }
  }
};
</script>
<style></style>