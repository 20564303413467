<template>
  <div class="bg-black font-geo">
    <PageHeader heading="" subheading="Join the Avlos team" img="/img/avlos-header-careers.png" />
    <div class="bg-white py-12 sm:py-[90px]">
      <div class="grid grid-cols-9 gap-10 sm:gap-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 sm:pb-[90px]">
        <div class="col-span-9">
          <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-2">
            Focused on delivering excellent software
          </p>
          <p class="pt-4">
            We strive for an elite, fast performing and inclusive environment that we are all proud to be a part of and that can help everyone work towards reaching individual aspirations while fulfilling the company's mission to make it easier to understand, build, and deploy software.
          </p>
          <p class="pt-4">
            If you are passionate about learning, working on ambitious projects, and want to find out more about our team, we'd love to hear from you.
          </p>
        </div>
        <div class="col-span-9 sm:col-span-4 pt-4">
          <div class="text-xl sm:text-2xl lg:text-3xl my-1">
            Joining Avlos means joining a team where
          </div>
        </div>
        <div class="col-span-9 sm:col-span-5 border-b-2 border-grayscale-line sm:pt-4">
          <check-list v-if="teamChecklist" class="text-black mb-5" :list="teamChecklist" />
        </div>
        <div class="col-span-9 sm:col-span-4">
          <div class="text-xl sm:text-2xl lg:text-3xl text-black my-1">
            Our priorities with benefits are wellness and education
          </div>
        </div>
        <div class="col-span-9 sm:col-span-5 border-b-2 border-grayscale-line pb-4">
          <div v-for="(item, index) in benefitsChecklist" :key="`avl-b-${index}`" class="grid grid-flow-row-dense grid-cols-12 justify-items-stretch items-start pb-1">
            <div class="col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-1 justify-self-end">
              <img class="w-[15px] pt-2" src="/img/check.svg" alt="check" />
            </div>
            <div class="col-span-11 sm:col-span-11 md:col-span-10 lg:col-span-11 pl-4">
              <span class="font-geo-md pr-1">{{ item.header }}</span>{{ item.descr }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-grayscale-off-white">
      <div class="grid grid-cols-9 gap-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 pb-[90px] pt-[90px]">
        <div class="col-span-9">
          <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-2">
            Open Roles
          </p>
        </div>
        <div class="col-span-9">
          <p class="text-xl sm:text-2xl lg:text-3xl text-black mb-2">
            No Open Roles
          </p>
          <p class="text-avl-bg-grey">
            Submit the form below to be notified when new roles become available.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="grid grid-cols-2 sm:gap-2 mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 pb-[90px] pt-[90px]">
        <div class="col-span-2">
          <p class="text-2xl sm:text-3xl lg:text-4xl text-black mb-2">
            Sign up for future updates
          </p>
        </div>
        <div class="col-span-1 pt-4">
          <form action="https://formkeep.com/f/15654e2df5a3" accept-charset="UTF-8" enctype="multipart/form-data" method="POST">
            <label for="careers-email">Your email *</label>
            <input type="email" name="email" id="careers-email" autocomplete="on" class="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" required />
            <div class="pt-6 pb-2">Which roles would you like to be notified about?</div>
            <div class="relative flex items-start pt-2" v-for="(item, index) in roleTypes" :key="`avl-role-${index}`">
              <div class="flex h-5 items-center">
                <input :id="`position-${index}`" aria-describedby="offers-description" :name="`${item}`" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-avl-copper focus:ring-avl-copper" />
              </div>
              <div class="ml-3 text-sm">
                <label for="offers" class="font-medium text-gray-700">{{item}}</label>
              </div>
            </div>
            <div class="pt-4 pb-5">
              <button type="submit" class="relative inline-flex items-center border border-transparent bg-avl-copper px-8 py-2 text-base font-medium shadow-sm hover:bg-orange-700">
                <span>Stay updated</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import MixinContent from '@/mixins/Content.js';
import CheckList from "@/components/CheckList.vue";

export default {
  name: "AvlosCareers",
  mixins: [MixinContent],
  components: {
    PageHeader,
    CheckList,
  },
  data() {
    return {
      teamChecklist: [
        "You take on big challenges.",
        "Everyone is treated with respect independent of their status or disagreement.",
        "You focus on great results rather than process.",
        "You learn rapidly from colleagues that are eager to help.",
        "You share your ideas, challenge prevailing assumptions, and suggest better approaches.",
        "You lead a balanced life and take care of your wellbeing."
      ],
      benefitsChecklist: [
        { header: "Top-Market Salary:", descr: "Salaries are computed taking into account experience and location to produce a result that attracts outstanding employees." },
        { header: "Medical Insurance:", descr: "We offer our employees private medical coverage to ensure that they have access to the care they need." },
        { header: "Learning Budget:", descr: "We cover costs for learning resources (e.g. books, courses) on areas of knowledge you want to expand." },
        { header: "Remote work:", descr: "Our employees have control over their work schedule and physical location." },
        { header: "Minimum vacation policy:", descr: "All employees are required to take a minimum of 4 weeks per year away from work." },
      ],
      roleTypes: ["All roles", "Engineering", "Business Development", "People Operations", "Design", "Marketing", "Finance", "Product Development"],
    }
  },
  mounted() {
    const rocket = String.fromCodePoint(0x1F680)
    console.log("%cInterested in developing with us? Reach out to careers@avlos.ai " + rocket, "color:#E85700; font-size:24px;");
  }
};
</script>
<style></style>