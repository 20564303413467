<template>
  <Disclosure as="nav" class="bg-black font-geo" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-40">
      <div class="relative flex h-16 items-center">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-300 hover:bg-gray-800 hover:text-white focus:outline-none">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center sm:items-stretch sm:justify-start grid grid-cols-6 gap-4">
          <div class="flex flex-shrink-0 items-center col-span-6 justify-center sm:col-span-2 sm:justify-start">
            <a href="/">
              <img class="hidden h-8 w-auto sm:block" src="/img/avlos-logo.svg" alt="Avlos, LLC" />
              <img class="block h-8 w-auto sm:hidden" src="/img/avlos-logo.svg" alt="Avlos, LLC" />
            </a>
          </div>
          <div class="hidden sm:block col-span-4">
            <div class="flex items-center justify-between">
              <div>
                <div class="fixed inset-0 w-0 h-0" @click="isVisible = false"></div>
                <div class="relative inline-block" @mouseover="isVisible = true" @mouseleave="isVisible = false" @keydown.enter="isVisible = !isVisible">
                  <button type="button" class="inline-flex items-center justify-between px-2 py-1 font-base text-white transition-all duration-500">
                    <span class="flex-shrink-0">Services</span>
                    <svg fill="currentColor" viewBox="0 0 20 20" class="flex-shrink-0 w-5 h-5 ml-1">
                      <path :class="{ 'rotate-180': isVisible }" class="transition duration-300 ease-in-out origin-center transform" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </button>
                  <transition enter-active-class="transition duration-300 ease-out transform" enter-class="-translate-y-3 scale-95 opacity-0" enter-to-class="translate-y-0 scale-100 opacity-100" leave-active-class="transition duration-150 ease-in transform" leave-class="translate-y-0 opacity-100" leave-to-class="-translate-y-3 opacity-0">
                    <div v-show="isVisible" class="absolute z-10 top-4">
                      <div class="relative py-1 bg-white rounded-md shadow-xl">
                        <div class=" absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5" static>
                            <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              <a v-for="item in resources" :key="item.name" :href="item.href" class="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out hover:bg-orange-50 group">
                                <component :is="item.icon" class="h-6 w-6 flex-shrink-0 group-hover:text-avl-copper" aria-hidden="true" />
                                <div class="ml-4">
                                  <p class="text-base font-base text-gray-900 group-hover:text-avl-copper">
                                    {{ item.name }}
                                  </p>
                                  <p class="mt-1 text-sm text-gray-500 group-hover:text-black">
                                    {{ item.description }}
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div class="bg-gray-50 px-5 py-5 sm:px-8 sm:py-8">
                              <div>
                                <h3 class="text-base font-base text-gray-500">
                                  Solutions
                                </h3>
                                <ul role="list" class="mt-4 space-y-4 w-full">
                                  <li v-for="post in recentPosts" :key="post.id" class="truncate text-base">
                                    <a :href="post.href" class="font-base text-gray-900 transition duration-150 ease-in-out hover:text-avl-copper">
                                      <div class="w-full">
                                        {{ post.name }}
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <a href="/company" class="text-white px-3 py-2 rounded-md text-base">Company</a>
              <a href="/careers" class="text-white px-3 py-2 rounded-md text-base">Careers</a>
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <button type="button" class="relative inline-flex items-center border border-transparent bg-avl-copper px-7 py-1 text-base font-base shadow-sm hover:bg-orange-700" @click="redirect('/contact')">
                    <span>Contact</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
            <a v-for="item in resources" :key="item.name" :href="item.href" class="-m-3 flex items-start rounded-lg p-3 transition duration-150 ease-in-out">
              <component :is="item.icon" class="h-6 w-6 flex-shrink-0 text-avl-copper" aria-hidden="true" />
              <div class="ml-4">
                <p class="text-base font-base text-gray-300">
                  {{ item.name }}
                </p>
              </div>
            </a>
          </div>
          <div class="px-5 py-5 sm:px-8 sm:py-8 border-t-2 border-grayscale-line">
            <div>
              <h3 class="text-base font-base text-gray-500">
                Solutions
              </h3>
              <ul role="list" class="mt-4 space-y-4">
                <li v-for="post in recentPosts" :key="post.id" class="truncate text-base">
                  <a :href="post.href" class="font-base text-gray-300 transition duration-150 ease-in-out hover:text-avl-copper">{{ post.name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <DisclosureButton v-for="item in navigation" :key="item.name" as="a" :href="item.href" :class="[
            item.current
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'block px-3 py-2 rounded-md text-base font-base',
          ]" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon, CodeBracketIcon, ChartBarIcon, RocketLaunchIcon } from "@heroicons/vue/24/outline";

const navigation = [
  { name: "Company", href: "/company", current: false },
  { name: "Careers", href: "/careers", current: false },
  { name: "Contact", href: "/contact", current: false },
];
const resources = [{
    name: "Technology Consulting",
    description: "Up performance and cut infrastructure costs with our DevOps services.",
    href: "/consulting",
    icon: RocketLaunchIcon,
  },
  {
    name: "Web & Mobile Development",
    description: "Plan, design, develop and launch custom software.",
    href: "/development",
    icon: CodeBracketIcon,
  },
  {
    name: "Data & Analytics",
    description: "Turn your data into value.",
    href: "/analytics",
    icon: ChartBarIcon,
  },
];
const recentPosts = [
  { id: 1, name: "For enterprises", href: "/enterprises" },
  {
    id: 2,
    name: "For startups",
    href: "/startups",
  },
];
</script>
<script>
export default {
  data() {
    return {
      isVisible: false
    }
  },
}
</script>
<style lang="scss">
@import "@/styles/global.scss";
</style>