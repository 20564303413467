<template>
  <div id="forEnterprises" class="bg-black font-geo">
    <PageHeader heading="Avlos for enterprises" subheading="Improve efficiency, reduce cost and participate in new market opportunities." img="/img/avlos-header-enterprises.png" />
    <div class="bg-white">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-40 grid grid-cols-3 gap-4 pb-[90px] pt-[90px]">
        <div class="col-span-3">
          <h1 class="tracking-tight text-2xl sm:text-3xl lg:text-4xl pb-10">
            Industries
          </h1>
        </div>
        <div class="col-span-3 sm:col-span-1 smoothScroll" v-scroll="handleScroll" v-for="item in feats" :key="item">
          <FeatCard :name="item" :descr="getDescr(item)" minHeight="min-h-0" />
        </div>
      </div>
    </div>
    <div class="bg-black">
      <div class="relative ">
       <!--  <div class="absolute inset-0">
          <img class="h-full w-full object-cover opacity-20" src="/img/avlos-summary-lg-2.png" alt="" />
        </div> -->
        <div class="relative mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-40">
          <list-enterprices headerList="Application modernization" subHeaderList="Perform data driven application assessments and adopt processes for faster, more secure software releases using proven DevOps practices." :paddingTop="false">
            <check-list class="text-white" :list="[
          'Hybrid and multicloud application platforms',
          'Serverless solutions',
          'DevOps',
          'Continuous integration',
          'Continuous delivery',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Artificial intelligence" subHeaderList="Add intelligence to your business with AI and machine learning.">
            <check-list class="text-white" :list="[
          'Data science',
          'Build and use AI',
          'Document AI',
          'Visual inspection AI',
          'Contact center AI',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Databases" subHeaderList="Let us help you unify data across your organization with an open and simplified approach that is optimized for availability, scale, and security with AI functionality built in.">
            <check-list class="text-white" :list="[
          'Database migration',
          'Database modernization',
          'AI solutions',
          'Smart analytics solutions',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Digital transformation" subHeaderList="Reimagine your operations and optimize operational costs.">
            <check-list class="text-white" :list="[
          'Operational efficiency',
          'Digital innovation',
          'Supply chain monitoring',
          'Business continuity',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Infrastructure modernization" subHeaderList="Migrate and modernize workloads with secure and reliable infrastructure.">
            <check-list class="text-white" :list="[
          'Application migration',
          'Data center migration',
          'High performance computing',
          'Disaster recovery',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Security" subHeaderList="Detect and stop cyber threats that target your business and user before attacks result in loss.">
            <check-list class="text-white" :list="[
          'Security analytics and operations',
          'Risk and compliance as code',
          'Web app and API protection',
        ]" />
          </list-enterprices>
          <list-enterprices headerList="Smart analytics" subHeaderList="Generate real time insights from data at any scale with  to drive business decisions and innovation." :line="false">
            <check-list class="text-white" :list="[
          'Data warehouse modernization',
          'Data lake modernization',
          'Stream analytics',
          'Business intelligence',
        ]" />
          </list-enterprices>
        </div>
      </div>
    </div>
    <next-step />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import FeatCard from "@/components/FeatCard.vue";
import CheckList from "@/components/CheckList.vue";
import ListEnterprices from "@/components/ListEnterprices.vue";
import NextStep from "@/components/NextStep.vue";
import MixinContent from '@/mixins/Content.js';

export default {
  name: "ForEnterprises",
  mixins: [MixinContent],
  components: { PageHeader, FeatCard, CheckList, ListEnterprices, NextStep },
  data() {
    return {
      feats: ["healthcare & life sciences", "sustainability", "telecommunications", "financial services", "government", "education"],
    }
  }
};
</script>
<style lang="scss">
</style>